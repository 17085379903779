export default {
	setAuthenticated(state, status) {
		state.isUserAuthenticated = status;
	},
	setAsideStatus(state, status) {
		state.asideOpen = status;
	},
	setMyAccount(state, account) {
		state.userAccount = account;
		// Vue.set(state.users, state.users.indexOf((u) => u.id == account.id), account);
	},
	// setUserAccount(state, account) {
	// Vue.set(state.users, state.users.indexOf((u) => u.id == account.id), account);
	// },
};
