import store from '@/store';

class Session {
	constructor() {
		this._token = null;
		this._user = null;
		this._key = 'cmb-user';
		// this.setUser(localStorage.getItem(this._key));
		this.isAuthenticated();
	}

	/**
   * Add event listener for authentication
   * @param func
   */
	onAuthentication(func) {
		this._onAuthCallbacks.push(func);
	}

	/**
   * Verify if user is authenticated
   * @returns {boolean}
   */
	isAuthenticated() {
		const isAuthed = !!localStorage.getItem('cmb-auth');
		if (isAuthed) store.commit('setAuthenticated', true);
	}

	/**
   * Get user
   * @returns {null|*}
   */
	getUser() {
		try {
			return JSON.parse(localStorage.getItem(this._key));
		} catch (error) {
			return null;
		}
	}

	/**
   * Set auth user in local storage
   * @param user
   */
	setUser(user) {
		this._user = user;
		if (this._user) {
			try {
				localStorage.setItem(this._key, JSON.stringify(this._user));
			} catch (err) {
				this._user = null;
				localStorage.removeItem(this._key);
			}
		}
	}

	/**
   * Set auth user in local storage
   * @param user
   */
	setAuthed(status) {
		try {
			localStorage.setItem('cmb-auth', status);
			store.commit('setAuthenticated', true);
		} catch (err) {
			this._user = null;
			localStorage.setItem('cmb-auth', false);
			store.commit('setAuthenticated', false);
		}
	}

	/**
   * Remove authentication
   */
	removeUser() {
		this._user = null;
		store.commit('setAuthenticated', false);
		localStorage.removeItem(this._key);
		localStorage.removeItem('cmb-auth');
	}
}

export default new Session();
