<template>
  <div :class="['site-wrapper', isPageLayout ? 'site-layout--default' : '', isAsideOpened ? 'site-wrapper--has-overlay' : '']">
    <Header>
      <component :is="headerComponent"></component>
    </Header>
      <!-- Content
      ================================================== -->
		<router-view></router-view>

    <Footer />
    <component :is="decoratorComponent"></component>
		<!-- Overlay -->
		<div class="site-overlay"></div>
		<!-- Overlay / End -->

		<Aside />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import LandingDecorator from './partials/decorators/LandingDecorator.vue';
import LandingHeader from './partials/headers/LandingHeader.vue';
import Aside from './partials/Aside.vue';
import Header from './Header.vue';
import Footer from './Footer.vue';

export default {
	name: 'Container',
	data() {
		return {
			headerComponent: null,
			decoratorComponent: null,
			isPageLayout: false,
		};
	},
	components: {
		Header,
		Footer,
		LandingHeader,
		LandingDecorator,
		Aside,
	},
	computed: {
		...mapGetters(['isAsideOpened', 'hasIncomingChallenges']),
	},
	watch: {
		$route: {
			handler(route) {
				if (route.name === 'Home') {
					this.headerComponent = 'LandingHeader';
					this.decoratorComponent = 'LandingDecorator';
				} else {
					this.headerComponent = null;
					this.decoratorComponent = null;
				}
				if (route.path.includes('pages')) {
					this.isPageLayout = true;
				} else {
					this.isPageLayout = false;
				}
			},
			deep: true,
			immediate: true,
		},
		hasIncomingChallenges(newValue) {
			if (newValue) {
				this.$store.commit('setAsideStatus', true);
			}
		},
	},

};
</script>

<style>

</style>
