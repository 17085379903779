var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "landing-detail landing-detail--left" }, [
        _c("span"),
        _c("span"),
        _c("span")
      ]),
      _c(
        "div",
        { staticClass: "landing-detail-cover landing-detail-cover--left" },
        [_c("span"), _c("span"), _c("span")]
      ),
      _c("div", { staticClass: "landing-detail landing-detail--right" }, [
        _c("span"),
        _c("span"),
        _c("span")
      ]),
      _c(
        "div",
        { staticClass: "landing-detail-cover landing-detail-cover--right" },
        [_c("span"), _c("span"), _c("span")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }