<template>
  <!-- Header
  ================================================== -->
  <div>
    <slot></slot>
    <header id="header" class="site-header site-header--bottom pinning-nav">

        <!-- Logo - Image Based -->
        <div class="header-logo header-logo--img">
          <a href="#"><img src="@/assets/img/logo.png" alt="Combet"></a>
        </div>
        <!-- Logo - Image Based / End -->
        <!-- Main Navigation -->
        <nav class="main-nav">
          <ul class="main-nav__list">
            <img class="header-avatar"
                v-if="isUserAuthenticated"
                :src="this.user.steam ? this.user.steam.photos[0].value : '/img/unknown-avatar.jpg'"
                :srcset="this.user.steam ? this.user.steam.photos[1].value : '/img/unknown-avatar@2x.jpg'" alt="">
            <a v-if="isUserAuthenticated" class="header-username ml-2">{{ this.user.steam ? this.user.steam.displayName : this.user.name }}</a>
            <router-link tag="li" to="/home" active-class="active" exact><a>HOME</a></router-link>
            <router-link tag="li" to="/account" active-class="active" exact><a>ACCOUNT</a></router-link>
            <router-link tag="li" to="/challenges" active-class="active" exact><a>CHALLENGES</a>
              <span v-if="hasActiveChallenges" class="main-nav__toggle-2">&nbsp;</span>
            </router-link>
            <!-- <router-link tag="li" to="/ranking" active-class="active" exact><a>RANKING</a></router-link> -->
          </ul>
        </nav>
        <!-- Main Navigation / End -->

        <!-- Header Actions -->
        <div class="header-actions">
          <div class="header-top-bar-toggle d-md-none hide">
            <svg role="img" class="df-icon df-icon--joystick">
              <use xlink:href="@/assets/theme/img/necromancers.svg#joystick"/>
            </svg>
            <svg role="img" class="df-icon df-icon--close">
              <use xlink:href="@/assets/theme/img/necromancers.svg#close"/>
            </svg>
          </div>
          <div class="header-social-toggle d-none d-md-block">
            <svg role="img" class="df-icon df-icon--thumb-up">
              <use xlink:href="@/assets/theme/img/necromancers.svg#thumb-up"/>
            </svg>
            <span class="header-social-toggle__plus">&nbsp;</span>
            <ul class="social-menu social-menu--header">
              <li><a href="https://discord.gg/xxxx"><span class="link-subtitle">Discord</span>Combet</a></li>
              <li><a href="https://twitter.com/danfisher_dev"><span class="link-subtitle">Twitter</span>Combet</a></li>
              <li><a href="https://www.facebook.com/danfisher.dev/"><span class="link-subtitle">Facebook</span>Combet</a></li>
            </ul>
          </div>
          <div class="header-search-toggle">
            <b-link to="/players">
              <svg role="img" class="df-icon df-icon--search">
                <use xlink:href="@/assets/theme/img/necromancers.svg#search"/>
              </svg>
            </b-link>
            <svg role="img" class="df-icon df-icon--search-close">
              <use xlink:href="@/assets/theme/img/necromancers.svg#search-close"/>
            </svg>
          </div>
          <div class="header-account hide">
            <div class="header-account__icon">
              <!-- <router-link tag="a" to="/pages/legal" class="text-white" active-class="active" exact>Legal</router-link>
              <router-link tag="a" to="/pages/coc" class="text-white" active-class="active" exact>CoC</router-link>
              <router-link tag="a" to="/pages/support" class="text-white" active-class="active" exact>Support</router-link>
              <router-link tag="a" to="/pages/about" class="text-white" active-class="active" exact>About</router-link> -->
              <a href="#" @click="logout()">
                <svg role="img" class="df-icon df-icon--logout">
                  <use xlink:href="@/assets/theme/img/necromancers.svg#logout"/>
                </svg>
              </a>
            </div>
          </div>
          <div class="header-menu-toggle">
            <div class="header-menu-toggle__inner">
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
            </div>
          </div>
        </div>
    </header>
  </div>

	<!-- Header / End -->
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'Header',
	methods: {
		async logout() {
			await this.$api.auth.logout();
			this.$session.removeUser();
			this.$router.push({ name: 'Home' });
		},
	},
	computed: {
		...mapGetters({
			hasActiveChallenges: 'hasActiveChallenges',
		}),
		isUserAuthenticated() {
			return this.$store.state.isUserAuthenticated;
		},
		user() {
			return this.$store.state.userAccount;
		},
	},
};
</script>

<style>
  .header-avatar {
    vertical-align: middle;
    width: 40px;
    height: 40px;
  }

  .header-username {
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: -.03em;
    line-height: 1.25;
    text-transform: uppercase;
    padding-right: 3rem;
  }

  .header-username :hover {
    color: #a3ff12 !important;
  }
</style>
