var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "site-content text-center", attrs: { id: "wrapper" } },
    [
      _c("div", { staticClass: "site-content__center" }, [
        _vm._m(0),
        _vm.isDeveloper
          ? _c("div", [
              !_vm.isUserAuthenticated
                ? _c(
                    "div",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-lg btn--landing",
                          attrs: { tag: "a", variant: "primary" },
                          on: { click: _vm.steamLogin }
                        },
                        [
                          _c("span", [
                            _c("i", { staticClass: "fab fa-steam fa-lg" }),
                            _vm._v(" Connect with Steam ")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn--landing btn-outlined mr-4",
                          attrs: { tag: "a", variant: "primary", size: "lg" }
                        },
                        [_c("span", [_vm._v("GET MATCHED")])]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "btn--landing btn-outlined ml-4",
                          attrs: {
                            tag: "a",
                            to: "/players",
                            variant: "primary",
                            size: "lg"
                          }
                        },
                        [_c("span", [_vm._v("SEARCH PLAYERS")])]
                      )
                    ],
                    1
                  )
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "text-white landing-title" }, [
      _c("span", { staticClass: "subtitle landing-subtitle" }, [
        _vm._v("CHALLENGE YOUR FRIENDS OR GET MATCHED WITH "),
        _c("br"),
        _vm._v(" OPPONENTS IN E-SPORT SKILL GAMES")
      ]),
      _vm._v(" GET PAID WHEN YOU WIN! ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }