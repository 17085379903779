import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import Toasted from 'vue-toasted';
import VueCountdown from '@chenfengyuan/vue-countdown';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// Services
import './serviceInjection';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Toasted, {
	theme: 'bubble',
	duration: 5000,
	position: 'top-right',
	className: 'notification',
});

Vue.component(VueCountdown.name, VueCountdown);

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');
