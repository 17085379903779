// Lib imports
import axios from 'axios';
import router from '@/router';
import session from '@/services/session';
import Vue from 'vue';

const request = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	timeout: 10000,
	withCredentials: true,
});

const registerResponseInterception = (request) => {
	request.interceptors.response.use((response) => response, (error) => {
		if (error.response && error.response.status === 401) {
			session.removeUser();
			if (router.currentRoute.name !== 'Home') {
				router.push({ name: 'Home' });
			}
			Vue.toasted.error('Auth Failed.');
			return Promise.reject(new Error('Access Expired.'));
		} if (error.response && error.response.data) {
			Vue.toasted.error(error.response.data.message || 'An error occurred');
			return Promise.reject(error);
		}
		return Promise.reject(error);
	});
};

// Check user for authorization
registerResponseInterception(request);

export default request;
