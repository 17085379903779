import Vue from 'vue';
import session from '@/services/session';
import axios from '@/services/axios';
import api from '@/services/api';
import socket from '@/services/socket';

Vue.prototype.$session = session;
Vue.prototype.$axios = axios;
Vue.prototype.$api = api;
Vue.prototype.$socket = socket;
