<template>
  <!-- Cart Panel -->
		<div class="cart-panel ">
			<div v-if="incomingChallenges && incomingChallenges.length > 1">
				<b-row class="mt-3">
					<b-col sm=12 class="text-center">
						<h3 class="text-white mb-0">Incoming</h3>
						<h2 style="color: #a3ff12">Challenges</h2>
					</b-col>
				</b-row>
				<div v-for="(challenge, index) in incomingChallenges" :key="index">
					<b-row :class="['justify-content-around', index === 0 ? 'mt-4' : 'mt-5']">
						<b-col sm=12 class="text-center">
							<!-- <h2 class="mb-0" style="color: #a3ff12" >Challenge Request</h2> -->
							<h5 class="text-white mb-3">A player wants to Challenge you!</h5>
							<h2 class="text-white" style="border: 1px solid #a2fb18;">CS:GO - 1 VS 1</h2>
						</b-col>
					</b-row>
					<b-row class="justify-content-around mt-3 mb-5">
							<b-button variant="primary" size="lg" @click="viewChallenge(index)">
								<span>VIEW</span>
							</b-button>
							<b-button variant="primary" class="btn-outlined" size="lg" @click="acceptChallenge(index)">
								<span>ACCEPT</span>
							</b-button>
							<b-button variant="primary" size="lg" class="btn-outlined-danger" @click="declineChallenge(index)">
								<span>DECLINE</span>
							</b-button>
					</b-row>
					<hr v-if="index !== incomingChallenges.length - 1"/>
				</div>
			</div>
			<div v-else-if="incomingChallenges && incomingChallenges.length === 1" >
				<b-row class="mt-5">
					<b-col sm=12 class="text-center">
						<h2 class="text-white mb-0">Incoming</h2>
						<h1 style="color: #a3ff12">Challenge</h1>
					</b-col>
				</b-row>
				<b-row class="justify-content-around mt-5">
					<b-col sm=12 class="text-center">
						<!-- <h2 class="mb-0" style="color: #a3ff12" >Challenge Request</h2> -->
						<h4 class="text-white mb-5">A player wants to Challenge you!</h4>
						<h1 class="text-white" style="border: 1px solid #a2fb18;">CS:GO - 1 VS 1</h1>
					</b-col>
				</b-row>
				<b-row class="justify-content-around mt-5">
						<b-button variant="primary" size="lg" @click="viewChallenge(0)">
							<span>VIEW</span>
						</b-button>
						<b-button variant="primary" class="btn-outlined" size="lg" @click="acceptChallenge(0)">
							<span>ACCEPT</span>
						</b-button>
						<b-button variant="primary" size="lg" class="btn-outlined-danger" @click="declineChallenge(0)">
							<span>DECLINE</span>
						</b-button>
				</b-row>
			</div>
			<div v-else>
				<b-row class="mt-5">
					<b-col sm=12 class="text-center">
						<h2 class="text-white mb-0">No Incoming</h2>
						<h1 style="color: #a3ff12">Challenges</h1>
					</b-col>
					<b-col sm=12 class="text-center">
						<h3 class="text-white mb-0">How did you open this ???</h3>
					</b-col>
				</b-row>
			</div>
		</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'Aside',
	methods: {
		viewChallenge(index) {
			this.$router.push({ name: 'MatchUp', params: { id: this.incomingChallenges[index].id } });
			this.$store.commit('setAsideStatus', false);
			this.$store.commit('removeIncomingChallenges');
		},
		async acceptChallenge(index) {
			const challenge = await this.$store.dispatch('acceptChallenge', this.incomingChallenges[index].id);
			this.$router.push({ name: 'MatchUp', params: { id: challenge.id } });
			this.$store.commit('setAsideStatus', false);
			this.$store.commit('removeIncomingChallenges');
		},
		async declineChallenge(index) {
			await this.$store.dispatch('declineChallenge', this.incomingChallenges[index].id);
			this.$store.commit('setAsideStatus', false);
			this.$store.commit('removeIncomingChallenges');
		},
	},
	computed: {
		...mapGetters({
			incomingChallenges: 'incomingChallenges',
		}),
	},
};
</script>
