<template>
  <!-- Footer
		================================================== -->
		<footer id="footer" class="footer text-center">
		</footer>
		<!-- Footer / End -->
</template>

<script>
export default {
	name: 'Footer',
};
</script>

<style>

</style>
