import api from '@/services/api';
import session from '@/services/session';

export default {
	async getChallenges({ commit }) {
		const user = session.getUser();
		if (user) {
			const challenges = await api.challenge.getChallengeByPlayerId(user.id);
			commit('setChallenges', challenges);
			return challenges;
		}
		return [];
	},
	async getChallengeById({ commit }, id) {
		const challenge = await api.challenge.getChallengeById(id);
		commit('addChallenge', challenge);
		return challenge;
	},
	async createChallenge({ commit }, invitedIds) {
		const newChallenge = await api.challenge.createChallenge('csgo', invitedIds, 0);
		const challenge = await api.challenge.getChallengeById(newChallenge.id);
		commit('addChallenge', challenge);
		return challenge;
	},
	async acceptChallenge({ commit }, id) {
		const updatedChallenge = await api.challenge.acceptChallenge(id);
		const challenge = await api.challenge.getChallengeById(updatedChallenge.id);
		commit('updateChallenge', { id, challenge });
		return challenge;
	},
	async declineChallenge({ commit }, id) {
		const deletedChallenge = await api.challenge.rejectChallenge(id);
		const challenge = await api.challenge.getChallengeById(deletedChallenge.id);
		commit('updateChallenge', { id, challenge });
		return challenge;
	},
};
