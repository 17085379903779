<template>
	<router-view></router-view>
</template>

<script>
export default {
	name: 'App',
	async created() {
		await this.startUp();
	},
	methods: {
		async startUp() {
			await this.$store.dispatch('getUserAccount');
			await this.$store.dispatch('getChallenges');
		},
	},
};
</script>

<style lang="scss">
	.page-item {
		.page-link {
			color: whitesmoke !important;
			// Opinionated: remove the "hand" cursor set previously for .page-link
			background-color: #13151e !important;
			border-color: #13151e !important;
		}

		&.active .page-link {
			z-index: 3;
			color: whitesmoke;
			background-color: #13151e !important;
			border-color: #a2fb18 !important;
		}

		&.disabled .page-link {
			color: whitesmoke !important;
			pointer-events: none;
			// Opinionated: remove the "hand" cursor set previously for .page-link
			cursor: auto;
			background-color: #13151e !important;
			border-color: #13151e !important;
		}
	}

	.btn-outlined {
		border: 2px solid #a2fb18;
		background-color: transparent;
		color: white;
	}

	.btn-outlined:hover {
		background-color: transparent !important;
		color: black !important;
		border: 2px solid #a2fb18;
	}

	.btn-outlined-danger {
		border: 2px solid red;
		background-color: transparent;
		color: white;
	}

	.btn-outlined-danger:hover {
		background-color: red !important;
		color: white !important;
		border: 2px solid red;
	}

	.btn.disabled, .btn:disabled, .button.disabled, .button:disabled {
    opacity: .65;
	}
	.btn-primary.disabled, .btn-primary:disabled {
			color: #fff;
			background-color: #416902;
			border-color: #416902;
	}
</style>
