const user = JSON.parse(localStorage.getItem('cmb-user'));

export default {
	getChallenges: (state) => state.challenges,
	activeChallenges: (state) => state.challenges.filter((challenge) => {
		if (challenge.removedOn) {
			return false;
		}
		const isStarted = !!challenge.startedOn;
		const isFinished = !!challenge.finishedOn;
		const isRunning = isStarted && !isFinished;
		const isExpired = !!challenge.expiredOn || new Date(challenge.expiresAt).getTime() < Date.now();
		if (isExpired || isFinished) {
			return false;
		}
		if (isRunning) {
			return true;
		}

		const isRejectedByAny = challenge.players.reduce((pv, cv) => pv || !!cv.rejectedOn, false);
		// const isAcceptedByAll = challenge.players.reduce((pv, cv) => pv && !!cv.acceptedOn, true);

		return !isRejectedByAny;
	}),
	hasActiveChallenges: (state, getters) => getters.activeChallenges.length > 0,
	getChallengeById: ({ challenges }) => (id) => challenges.find((challenge) => challenge.id === id),
	getActiveChallengeById: (state, getters) => (id) => getters.activeChallenges.find((activeChallenge) => activeChallenge.id === id),
	sentChallenges: (state, getters) => {
		if (user && user.id) {
			return getters.activeChallenges.filter((challenge) => challenge.creatorId === user.id);
		}
		return [];
	},
	receivedChallenges: (state, getters) => {
		if (user && user.id) {
			return getters.activeChallenges.filter((challenge) => challenge.creatorId !== user.id);
		}
		return [];
	},
	incomingChallenges: (state) => state.incomingChallenges,
	hasReceivedChallenges: (state, getters) => getters.receivedChallenges.length > 0,
	hasIncomingChallenges: (state, getters) => getters.incomingChallenges.length > 0,
	hasSentChallenges: (state, getters) => getters.sentChallenges.length > 0,
};
