var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cart-panel " }, [
    _vm.incomingChallenges && _vm.incomingChallenges.length > 1
      ? _c(
          "div",
          [
            _c(
              "b-row",
              { staticClass: "mt-3" },
              [
                _c(
                  "b-col",
                  { staticClass: "text-center", attrs: { sm: "12" } },
                  [
                    _c("h3", { staticClass: "text-white mb-0" }, [
                      _vm._v("Incoming")
                    ]),
                    _c("h2", { staticStyle: { color: "#a3ff12" } }, [
                      _vm._v("Challenges")
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._l(_vm.incomingChallenges, function(challenge, index) {
              return _c(
                "div",
                { key: index },
                [
                  _c(
                    "b-row",
                    {
                      class: [
                        "justify-content-around",
                        index === 0 ? "mt-4" : "mt-5"
                      ]
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-center", attrs: { sm: "12" } },
                        [
                          _c("h5", { staticClass: "text-white mb-3" }, [
                            _vm._v("A player wants to Challenge you!")
                          ]),
                          _c(
                            "h2",
                            {
                              staticClass: "text-white",
                              staticStyle: { border: "1px solid #a2fb18" }
                            },
                            [_vm._v("CS:GO - 1 VS 1")]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "justify-content-around mt-3 mb-5" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary", size: "lg" },
                          on: {
                            click: function($event) {
                              return _vm.viewChallenge(index)
                            }
                          }
                        },
                        [_c("span", [_vm._v("VIEW")])]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-outlined",
                          attrs: { variant: "primary", size: "lg" },
                          on: {
                            click: function($event) {
                              return _vm.acceptChallenge(index)
                            }
                          }
                        },
                        [_c("span", [_vm._v("ACCEPT")])]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-outlined-danger",
                          attrs: { variant: "primary", size: "lg" },
                          on: {
                            click: function($event) {
                              return _vm.declineChallenge(index)
                            }
                          }
                        },
                        [_c("span", [_vm._v("DECLINE")])]
                      )
                    ],
                    1
                  ),
                  index !== _vm.incomingChallenges.length - 1
                    ? _c("hr")
                    : _vm._e()
                ],
                1
              )
            })
          ],
          2
        )
      : _vm.incomingChallenges && _vm.incomingChallenges.length === 1
      ? _c(
          "div",
          [
            _c(
              "b-row",
              { staticClass: "mt-5" },
              [
                _c(
                  "b-col",
                  { staticClass: "text-center", attrs: { sm: "12" } },
                  [
                    _c("h2", { staticClass: "text-white mb-0" }, [
                      _vm._v("Incoming")
                    ]),
                    _c("h1", { staticStyle: { color: "#a3ff12" } }, [
                      _vm._v("Challenge")
                    ])
                  ]
                )
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "justify-content-around mt-5" },
              [
                _c(
                  "b-col",
                  { staticClass: "text-center", attrs: { sm: "12" } },
                  [
                    _c("h4", { staticClass: "text-white mb-5" }, [
                      _vm._v("A player wants to Challenge you!")
                    ]),
                    _c(
                      "h1",
                      {
                        staticClass: "text-white",
                        staticStyle: { border: "1px solid #a2fb18" }
                      },
                      [_vm._v("CS:GO - 1 VS 1")]
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "justify-content-around mt-5" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "primary", size: "lg" },
                    on: {
                      click: function($event) {
                        return _vm.viewChallenge(0)
                      }
                    }
                  },
                  [_c("span", [_vm._v("VIEW")])]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "btn-outlined",
                    attrs: { variant: "primary", size: "lg" },
                    on: {
                      click: function($event) {
                        return _vm.acceptChallenge(0)
                      }
                    }
                  },
                  [_c("span", [_vm._v("ACCEPT")])]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "btn-outlined-danger",
                    attrs: { variant: "primary", size: "lg" },
                    on: {
                      click: function($event) {
                        return _vm.declineChallenge(0)
                      }
                    }
                  },
                  [_c("span", [_vm._v("DECLINE")])]
                )
              ],
              1
            )
          ],
          1
        )
      : _c(
          "div",
          [
            _c(
              "b-row",
              { staticClass: "mt-5" },
              [
                _c(
                  "b-col",
                  { staticClass: "text-center", attrs: { sm: "12" } },
                  [
                    _c("h2", { staticClass: "text-white mb-0" }, [
                      _vm._v("No Incoming")
                    ]),
                    _c("h1", { staticStyle: { color: "#a3ff12" } }, [
                      _vm._v("Challenges")
                    ])
                  ]
                ),
                _c(
                  "b-col",
                  { staticClass: "text-center", attrs: { sm: "12" } },
                  [
                    _c("h3", { staticClass: "text-white mb-0" }, [
                      _vm._v("How did you open this ???")
                    ])
                  ]
                )
              ],
              1
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }