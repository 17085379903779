<template>
  <header id="landing" class="site-header site-header--landing">
			<!-- Logo - Image Based -->
			<div class="header-logo header-logo--img">
				<a href="#"><img src="@/assets/img/logo@2x.png" alt="Combet"></a>
			</div>
  </header>
</template>

<script>
export default {
	name: 'LandingHeader',
};
</script>

<style>

</style>
