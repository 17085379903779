import Vue from 'vue';
import VueRouter from 'vue-router';
import Container from '@/layout/Container.vue';
import Home from '@/views/Home.vue';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		redirect: { name: 'Home' },
		name: 'App',
		component: Container,
		children: [
			{
				path: '/home',
				name: 'Home',
				component: Home,
			},
			{
				path: '/account',
				name: 'Account',
				beforeEnter: (to, from, next) => {
					if (store.getters.isUserAuthenticated) {
						next();
					} else {
						next({ name: 'Home' });
					}
				},
				redirect: { name: 'General' },
				component: () => import(/* webpackChunkName: "profile" */ '@/layout/account/Container'),
				children: [
					{
						path: 'general',
						name: 'General',
						component: () => import(/* webpackChunkName: "general" */ '../views/Account/General'),
					},
					{
						path: 'Wallet',
						name: 'Wallet',
						component: () => import(/* webpackChunkName: "wallet" */ '../views/Account/Wallet'),
					},
					{
						path: 'gameplay',
						name: 'Gameplay',
						component: () => import(/* webpackChunkName: "gameplay" */ '../views/Account/Gameplay'),
					},
				],
			},
			{
				path: '/challenges/:type',
				name: 'ChallengeType',
				beforeEnter: (to, from, next) => {
					if (store.getters.isUserAuthenticated) {
						next();
					} else {
						next({ name: 'Home' });
					}
				},
				component: () => import(/* webpackChunkName: "challenges" */ '@/views/Challenges'),
			},
			{
				path: '/challenges',
				name: 'Challenges',
				redirect: '/challenges/active',
			},
			{
				path: '/ranking',
				name: 'Ranking',
				component: () => import(/* webpackChunkName: "ranking" */ '@/views/Ranking.vue'),
			},
			{
				path: '/players',
				name: 'Players',
				component: () => import(/* webpackChunkName: "players" */ '@/views/Players.vue'),
			},
			{
				path: '/matchup/:id',
				name: 'MatchUp',
				component: () => import(/* webpackChunkName: "players" */ '@/views/MatchUp.vue'),
			},
			{
				path: '/match/:id/:challengeId',
				name: 'MatchResult',
				component: () => import(/* webpackChunkName: "players" */ '@/views/MatchResult.vue'),
			},
			{
				path: '/pages/about',
				name: 'AboutUs',
				component: () => import(/* webpackChunkName: "about" */ '@/views/Pages/AboutUs'),
			},
			{
				path: '/pages/support',
				name: 'Support',
				component: () => import(/* webpackChunkName: "support" */ '@/views/Pages/Support'),
			},
			{
				path: '/pages/legal',
				name: 'Legal',
				component: () => import(/* webpackChunkName: "legal" */ '@/views/Pages/Legal'),
			},
			{
				path: '/pages/coc',
				name: 'COC',
				component: () => import(/* webpackChunkName: "coc" */ '@/views/Pages/CodeOfConduct'),
			},
		],
	},
	{
		path: '/_ah/health',
		name: 'HealthCheck',
		component: () => import(/* webpackChunkName: "players" */ '@/views/HealthCheck.vue'),
	},

];

const router = new VueRouter({
	mode: 'history',
	linkActiveClass: 'active',
	routes,
});

export default router;
