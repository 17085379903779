import api from '@/services/api';
import session from '@/services/session';

export default {
	async getUserAccount({ commit }) {
		const userAccount = await api.account.getAccount();
		commit('setMyAccount', userAccount);
		session.setUser(userAccount);
		session.setAuthed(true);
	},

	async updateUserAccount({ commit }, account) {
		await api.account.updateAccount(account);
		const userAccount = await api.account.getAccount();
		commit('setMyAccount', userAccount);
		session.setUser(account);
	},
};
