<template>
	<main class="site-content text-center" id="wrapper">
    <div class="site-content__center">
			<h1 class="text-white landing-title">
				<span class="subtitle landing-subtitle">CHALLENGE YOUR FRIENDS OR GET MATCHED WITH <br> OPPONENTS IN E-SPORT SKILL GAMES</span>
				GET PAID WHEN YOU WIN!
			</h1>
			<div v-if="isDeveloper">
				<div v-if="!isUserAuthenticated">
					<b-button tag="a" @click="steamLogin" variant="primary" class="btn-lg btn--landing">
						<span>
							<i class="fab fa-steam fa-lg"></i>
							Connect with Steam
						</span>
					</b-button>
				</div>
				<div v-else>
					<b-button tag="a" variant="primary" size="lg" class="btn--landing btn-outlined mr-4">
						<span>GET MATCHED</span>
					</b-button>
					<b-button tag="a" to="/players" variant="primary" size="lg" class="btn--landing btn-outlined ml-4">
						<span>SEARCH PLAYERS</span>
					</b-button>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex';

export default {
	name: 'Home',
	methods: {
		steamLogin() {
			this.$api.auth.steamLogin();
		},
	},
	computed: {
		...mapGetters(['isUserAuthenticated']),
		isDeveloper: () => window.localStorage.isDev == 1,
	},
};
</script>
