import io from 'socket.io-client';
import store from '@/store';
import api from '@/services/api';

const isDev = localStorage.getItem('isDev') == '1';
const socket = io(process.env.VUE_APP_WS_URI);

socket.on('connect', () => {
	if (isDev) console.log(`SocketIO connected: ${process.env.VUE_APP_WS_URI}`);
	api.auth.wsToken().then((token) => {
		socket.emit('auth', token);
	});
});

if (isDev) {
	socket.on('connect_error', (a) => {
		console.log('connect_error', a);
	});
	socket.on('connect_timeout', (a) => {
		console.log('connect_timeout', a);
	});
	socket.on('reconnect', (a) => {
		console.log('reconnect', a);
	});
}

socket.on('incomingChallenge', async (newChallenge) => {
	const challenge = await api.challenge.getChallengeById(newChallenge.id);
	console.log(`challenge received: ${newChallenge.id}`);
	store.commit('addChallenge', challenge);
	store.commit('addIncomingChallenge', challenge);
});

socket.on('updatedChallenge', async (updateChallenge) => {
	const { id } = updateChallenge;
	console.log(`challenge updated: ${id}`);
	const challenge = await api.challenge.getChallengeById(id);
	store.commit('updateChallenge', { id, challenge });
});

export default socket;
