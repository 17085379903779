var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "header",
      {
        staticClass: "site-header site-header--landing",
        attrs: { id: "landing" }
      },
      [
        _c("div", { staticClass: "header-logo header-logo--img" }, [
          _c("a", { attrs: { href: "#" } }, [
            _c("img", {
              attrs: { src: require("@/assets/img/logo@2x.png"), alt: "Combet" }
            })
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }