export default {
	setChallenges(state, challenges) {
		challenges.forEach((challenge) => {
			state.challenges.push(challenge);
		});
		// state.activeChallenges = [...state.activeChallenges, ...challenges];
	},
	addChallenge(state, challenge) {
		state.challenges.push(challenge);
	},
	updateChallenge(state, { id, challenge }) {
		const index = state.challenges.findIndex((challenge) => challenge.id === id);
		if (index !== -1) {
			state.challenges.splice(index, 1, challenge);
		}
	},
	addIncomingChallenge(state, challenge) {
		state.incomingChallenges.push(challenge);
	},
	removeIncomingChallenges(state) {
		state.incomingChallenges.splice(0, state.incomingChallenges.length);
	},
};
