import request from '@/services/axios';
import session from '@/services/session';

// export api
const api = {
	auth: {
		async login(email, password) {
			const user = await request.post('auth/login', {
				email,
				password,
			});
			return user;
		},
		async steamLogin() {
			window.open(`${process.env.VUE_APP_API_URL}auth/steam`, '_top');
		},
		async logout() {
			session.removeUser();
			return request.get('auth/logout');
		},
		async wsToken() {
			return request.post('auth/wstoken', {});
		},
	},
	account: {
		async createAccount(account) {
			return request.post('account', {
				account,
			});
		},
		async updateAccount(account) {
			return request.patch('account', {
				account,
			});
		},
		async getAccount() {
			return request.get('account');
		},
		async changePassword(oldPassword, password) {
			return request.patch('account/password', {
				oldPassword,
				password,
			});
		},
		async verifyAccount(token) {
			return request.post('account/verify/email', {
				token,
			});
		},
	},
	players: {
		getPlayers() {
			return request.get('player');
		},
	},
	challenge: {
		createChallenge(gameId = 'csgo', invitedIds, stake = 0, gameMode = '1:1') {
			return request.post('challenge', {
				gameId,
				invitedIds,
				stake,
				gameMode,
			});
		},
		getChallengeById(id) {
			return request.get(`challenge/${id}`);
		},
		acceptChallenge(challengeId) {
			return request.patch(`challenge/accept/${challengeId}`);
		},
		rejectChallenge(challengeId) {
			return request.patch(`challenge/reject/${challengeId}`);
		},
		getChallengeByPlayerId(playerId) {
			return request.get(`challenge/by/playerId/${playerId}`);
		},
		getChallengeByCreatorId(creatorId) {
			return request.get(`challenge/by/playerId/${creatorId}`);
		},
	},
	game: {
		getGame() {
			return request.get('game');
		},
	},
	match: {
		getMatch(id) {
			return request.get(`game-match/${id}`);
		},
	},
};

const apiProxy = (obj, path = '') => {
	Object.entries(obj).forEach(([key, func]) => {
		if (typeof func !== 'function') return apiProxy(func, `${path}${path ? '.' : ''}${key}`); // func is not a function, deep recursive

		obj[key] = async (...args) => {
			try {
				const result = await func(...args);
				if (process.env.NODE_ENV === 'development') {
					console.log(`API ${path}${path ? '.' : ''}${key}, METHOD: ${result.config.method}, STATUS: ${result.status}, RESULT:`, result);
				}
				return result.data;
			} catch (err) {
				if (process.env.NODE_ENV === 'development') {
					// TODO: uniform error
					if (err.response) {
						console.error(`API ${path}${path ? '.' : ''}${key}, METHOD: ${err.response.config.method}, STATUS: ${err.response.status}, ERROR:`, err);
					} else if (err.request) {
						console.error(`API ${path}${path ? '.' : ''}${key}, EMPTY_RESPONSE, ERROR:`, err);
					} else {
						console.error(`API ${path}${path ? '.' : ''}${key}, CONFIG_ERROR, ERROR:`, err);
					}
				}
				throw err;
			}
		};
	});
	return api;
};

export default apiProxy(api);
