var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default"),
      _c(
        "header",
        {
          staticClass: "site-header site-header--bottom pinning-nav",
          attrs: { id: "header" }
        },
        [
          _vm._m(0),
          _c("nav", { staticClass: "main-nav" }, [
            _c(
              "ul",
              { staticClass: "main-nav__list" },
              [
                _vm.isUserAuthenticated
                  ? _c("img", {
                      staticClass: "header-avatar",
                      attrs: {
                        src: this.user.steam
                          ? this.user.steam.photos[0].value
                          : "/img/unknown-avatar.jpg",
                        srcset: this.user.steam
                          ? this.user.steam.photos[1].value
                          : "/img/unknown-avatar@2x.jpg",
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _vm.isUserAuthenticated
                  ? _c("a", { staticClass: "header-username ml-2" }, [
                      _vm._v(
                        _vm._s(
                          this.user.steam
                            ? this.user.steam.displayName
                            : this.user.name
                        )
                      )
                    ])
                  : _vm._e(),
                _c(
                  "router-link",
                  {
                    attrs: {
                      tag: "li",
                      to: "/home",
                      "active-class": "active",
                      exact: ""
                    }
                  },
                  [_c("a", [_vm._v("HOME")])]
                ),
                _c(
                  "router-link",
                  {
                    attrs: {
                      tag: "li",
                      to: "/account",
                      "active-class": "active",
                      exact: ""
                    }
                  },
                  [_c("a", [_vm._v("ACCOUNT")])]
                ),
                _c(
                  "router-link",
                  {
                    attrs: {
                      tag: "li",
                      to: "/challenges",
                      "active-class": "active",
                      exact: ""
                    }
                  },
                  [
                    _c("a", [_vm._v("CHALLENGES")]),
                    _vm.hasActiveChallenges
                      ? _c("span", { staticClass: "main-nav__toggle-2" })
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "header-actions" }, [
            _c("div", { staticClass: "header-top-bar-toggle d-md-none hide" }, [
              _c(
                "svg",
                {
                  staticClass: "df-icon df-icon--joystick",
                  attrs: { role: "img" }
                },
                [
                  _c("use", {
                    attrs: {
                      "xlink:href":
                        require("@/assets/theme/img/necromancers.svg") +
                        "#joystick"
                    }
                  })
                ]
              ),
              _c(
                "svg",
                {
                  staticClass: "df-icon df-icon--close",
                  attrs: { role: "img" }
                },
                [
                  _c("use", {
                    attrs: {
                      "xlink:href":
                        require("@/assets/theme/img/necromancers.svg") +
                        "#close"
                    }
                  })
                ]
              )
            ]),
            _c(
              "div",
              { staticClass: "header-social-toggle d-none d-md-block" },
              [
                _c(
                  "svg",
                  {
                    staticClass: "df-icon df-icon--thumb-up",
                    attrs: { role: "img" }
                  },
                  [
                    _c("use", {
                      attrs: {
                        "xlink:href":
                          require("@/assets/theme/img/necromancers.svg") +
                          "#thumb-up"
                      }
                    })
                  ]
                ),
                _c("span", { staticClass: "header-social-toggle__plus" }),
                _vm._m(1)
              ]
            ),
            _c(
              "div",
              { staticClass: "header-search-toggle" },
              [
                _c("b-link", { attrs: { to: "/players" } }, [
                  _c(
                    "svg",
                    {
                      staticClass: "df-icon df-icon--search",
                      attrs: { role: "img" }
                    },
                    [
                      _c("use", {
                        attrs: {
                          "xlink:href":
                            require("@/assets/theme/img/necromancers.svg") +
                            "#search"
                        }
                      })
                    ]
                  )
                ]),
                _c(
                  "svg",
                  {
                    staticClass: "df-icon df-icon--search-close",
                    attrs: { role: "img" }
                  },
                  [
                    _c("use", {
                      attrs: {
                        "xlink:href":
                          require("@/assets/theme/img/necromancers.svg") +
                          "#search-close"
                      }
                    })
                  ]
                )
              ],
              1
            ),
            _c("div", { staticClass: "header-account hide" }, [
              _c("div", { staticClass: "header-account__icon" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        return _vm.logout()
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "df-icon df-icon--logout",
                        attrs: { role: "img" }
                      },
                      [
                        _c("use", {
                          attrs: {
                            "xlink:href":
                              require("@/assets/theme/img/necromancers.svg") +
                              "#logout"
                          }
                        })
                      ]
                    )
                  ]
                )
              ])
            ]),
            _vm._m(2)
          ])
        ]
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header-logo header-logo--img" }, [
      _c("a", { attrs: { href: "#" } }, [
        _c("img", {
          attrs: { src: require("@/assets/img/logo.png"), alt: "Combet" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "social-menu social-menu--header" }, [
      _c("li", [
        _c("a", { attrs: { href: "https://discord.gg/xxxx" } }, [
          _c("span", { staticClass: "link-subtitle" }, [_vm._v("Discord")]),
          _vm._v("Combet")
        ])
      ]),
      _c("li", [
        _c("a", { attrs: { href: "https://twitter.com/danfisher_dev" } }, [
          _c("span", { staticClass: "link-subtitle" }, [_vm._v("Twitter")]),
          _vm._v("Combet")
        ])
      ]),
      _c("li", [
        _c(
          "a",
          { attrs: { href: "https://www.facebook.com/danfisher.dev/" } },
          [
            _c("span", { staticClass: "link-subtitle" }, [_vm._v("Facebook")]),
            _vm._v("Combet")
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header-menu-toggle" }, [
      _c("div", { staticClass: "header-menu-toggle__inner" }, [
        _c("span"),
        _c("span"),
        _c("span")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }