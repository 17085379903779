<template>
  <div>
    <div class="landing-detail landing-detail--left">
			<span>&nbsp;</span>
			<span>&nbsp;</span>
			<span>&nbsp;</span>
		</div>
		<div class="landing-detail-cover landing-detail-cover--left">
			<span>&nbsp;</span>
			<span>&nbsp;</span>
			<span>&nbsp;</span>
		</div>
		<div class="landing-detail landing-detail--right">
			<span>&nbsp;</span>
			<span>&nbsp;</span>
			<span>&nbsp;</span>
		</div>
		<div class="landing-detail-cover landing-detail-cover--right">
			<span>&nbsp;</span>
			<span>&nbsp;</span>
			<span>&nbsp;</span>
		</div>
  </div>

</template>

<script>
export default {
	name: 'LandingDecorator',
};
</script>

<style>

</style>
