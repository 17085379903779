var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "site-wrapper",
        _vm.isPageLayout ? "site-layout--default" : "",
        _vm.isAsideOpened ? "site-wrapper--has-overlay" : ""
      ]
    },
    [
      _c("Header", [_c(_vm.headerComponent, { tag: "component" })], 1),
      _c("router-view"),
      _c("Footer"),
      _c(_vm.decoratorComponent, { tag: "component" }),
      _c("div", { staticClass: "site-overlay" }),
      _c("Aside")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }